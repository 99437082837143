import {DataColumns} from 'o365-datagrid';
import { parseColumnsFromVNodes } from 'o365-datagrid';

export function getDataColumnsFromProp(props : any, propName : string) : DataColumns {
    if (!props[propName]) {
        return null;
    }
 
    return new DataColumns({},props[propName], {
        initialColumnsOptions: {
            addSpecialGridColumns: false
        }
    });
}

export function getDataColumnsFromSlot(slots : object, slotName : string, props : any) : DataColumns {
    if (!slots[slotName]) {
        if (slotName == "default") {
          throw `Must provide atleast one OColumn in ${slotName} slot`;
        }
        return null;
    }
    const vnodes = slots[slotName]();
    const parsedColumns = parseColumnsFromVNodes(vnodes);
    return new DataColumns({},parsedColumns, {
        initialColumnsOptions: {
            addSpecialGridColumns: false
        }
    });
}